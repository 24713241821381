import React from "react";
import Layout from "../../components/layout";

const Arch = () => {
    return (
        <Layout>
            <h1>Architecture Team</h1>
        </Layout>
    )
};

export default Arch;
